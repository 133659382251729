<script>
import IndicesTable from "@/views/pages/dashboard/indices-table";

export default {
  components: {
    IndicesTable
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    model:{
      type: Object,
      required: true
    }
  },
  data() {
    return {
     
    };
  }
};
</script>

<template>
  <div class="card cardc8 main-card">
    <div class="card-content">
      <div class="card-body">
        <b-tabs nav-class="nav-tabs-custom" content-class="p-3 text-muted">
          <b-tab active >
            <template v-slot:title>
              
              <span class="">Active Index</span>
            </template>
            <indices-table :items="model.activeIndex"></indices-table>
          </b-tab>
          <b-tab >
            <template v-slot:title>
              
              <span class="">Passive Index</span>
            </template>
            <indices-table :items="model.passiveIndex"></indices-table>
          </b-tab>
          <b-tab>
            <template v-slot:title>
             
              <span class="">ETF</span>
            </template>
            <indices-table :items="model.etf"></indices-table>
          </b-tab>
           <b-tab >
            <template v-slot:title>
              
              <span class="">Crypto</span>
            </template>
            <indices-table :items="model.cryptoIndex"></indices-table>
          </b-tab>
           <!-- <b-tab>
            <template v-slot:title>
             
              <span class="">Overlay</span>
            </template>
            <indices-table :items="model.overlayIndex"></indices-table>
          </b-tab> -->
          <b-tab >
            <template v-slot:title>
              
              <span class="">My Indices</span>
            </template>
            <indices-table :items="model.myIndices"></indices-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>