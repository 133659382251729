<script>
import Layout from "../../layouts/horizontal-absolute";
import IndicesCard from "@/views/pages/dashboard/indices-card";
import http from '@/http'


export default {
  components: {
    Layout,
    IndicesCard
  },
  created(){
    this.load();
  },
  data() {
    return {
      indexViewHistory:[],
      isBusy: false,
      model: null,
      title: 'Performance Overview',
      breadcrumbs: [
        {
          text:  'Performance Overview',
          active: true
        }
      ]
    };
  },
  
  methods: {
    clearRecintlyViewed() {
      localStorage.setItem('index_view_history', JSON.stringify([]));
      this.indexViewHistory = [];
    },
    onFilterApply() {
      this.$router.push({ name: "indices" });
    },
    async load() {
      this.isBusy = true;
      
      let dataStr = localStorage.getItem('index_view_history');
      let indexViewHistory = [];
      if(dataStr) {
        indexViewHistory = JSON.parse(dataStr);
      } else {
        indexViewHistory = [];
      }
      this.indexViewHistory = indexViewHistory.reverse();

      const resp = await http.get( 'api/dashboard');
      // resp.data.news.forEach(x => x.dateTimeFormatted = moment(x.dateTime).format('LL'));
      this.model = resp.data;
      this.isBusy = false;
    },
  }
};
</script>

<template>
  <Layout>
    
      <div class="performance-overview" >
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
              <h4 class="mb-0"> 
                <span  class="title-content">
                  {{ title }} <i v-if="isBusy"  class="fa fa-spin fa-spinner ml-2"></i>
                </span>
                
              </h4>
              <div class="page-title-right  d-none d-lg-block">
                <b-breadcrumb :items="breadcrumbs" class="m-0"></b-breadcrumb>
              </div>
            </div>
          </div>
        </div>
    
       <indices-card v-if="model" title="Performance" :model="model.performance"></indices-card>
    </div>
  </Layout>
</template>