<script>


export default {
  components: {

  },
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  computed: {
    sortedItems() {
      let o = [...this.items];
   
      if(this.sortBy === 'name') {
        o = o.sort(((a,b) => a.displayName.trim() > b.displayName.trim() ? 1 : -1));
      } else  if(this.sortBy === 'ytd') {
        o = o.sort((a, b) => a.performance.ytd > b.performance.ytd  ? 1 : -1);
      } else  if(this.sortBy === 'mtd') {
        o = o.sort((a, b) => a.performance.mtd > b.performance.mtd ? 1 : -1);
      } else  if(this.sortBy === 'annualised-return') {
        o = o.sort((a, b) => ((a.statistic || {}).values || {}).annualisedReturn > ((b.statistic || {}).values || {}).annualisedReturn ? 1 : -1);
      } else  if(this.sortBy === 'realised-volatility') {
        o = o.sort((a, b) => ((a.statistic || {}).values || {}).realisedVolatility > ((b.statistic || {}).values || {}).realisedVolatility ? 1 : -1);
      } else  if(this.sortBy === 'maxDrawdown') {
        o = o.sort((a, b) => ((a.statistic || {}).values || {}).maxDrawdown > ((b.statistic || {}).values || {}).maxDrawdown ? 1 : -1);
      } else  if(this.sortBy === 'sharpeRatio') {
        o = o.sort((a, b) => ((a.statistic || {}).values || {}).sharpeRatio > ((b.statistic || {}).values || {}).sharpeRatio? 1 : -1);  
      }
      o = this.sortByDir === 'asc' ? o : o.reverse();

      return o;
    },
  },
  data() {
    return {
     sortBy: 'ytd',
     sortByDir: 'desc'
    };
  },
  methods: {
    sort(field){
      if(this.sortBy === field) {
        this.sortByDir = this.sortByDir === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = field;
      }
    }
  }
};
</script>

<template>
  <table class="table table-centered mb-0 table-nowrap" >
    <thead>
      <tr>
        <th style="cursor:pointer; min-width:200px; max-width:300px; " @click="sort('name')">
          <span class="mr-1"> {{$t('tables.indices.columns.name')}}</span>
          
          <span class="ml-2 mt-2" v-if="sortBy === 'name'">
            <span v-if="sortByDir === 'asc'">
              <i class="fa  fas fa-sort-up"></i>
            </span>
            <span v-if="sortByDir === 'desc'">
              <i class="fa  fas fa-sort-down"></i>
            </span>
          </span> 
        </th>
        <th style="cursor:pointer; "  @click="sort('ytd')">
          <span class="mr-1">{{$t('tables.indices.columns.ytd')}}</span>
          <span class="ml-2" v-if="sortBy === 'ytd'" >
            <span v-if="sortByDir === 'asc'">
              <i class="fa  fas fa-sort-up"></i>
            </span>
            <span v-if="sortByDir === 'desc'">
              <i class="fa  fas fa-sort-down"></i>
            </span>
          </span> 
        </th>
        <th style="cursor:pointer; " @click="sort('mtd')">
          <span class="mr-1">{{$t('tables.indices.columns.mtd')}}</span>
           <span class="ml-2 mt-2" v-if="sortBy === 'mtd'" >
            <span v-if="sortByDir === 'asc'">
              <i class="fa  fas fa-sort-up"></i>
            </span>
            <span v-if="sortByDir === 'desc'">
              <i class="fa  fas fa-sort-down"></i>
            </span>
          </span> 
        </th>
        <th style="cursor:pointer;max-width:90px;white-space: normal;"  @click="sort('annualised-return')">
          <span class="mr-1">{{$t('tables.indices.columns.annualisedReturn')}}</span>
           <span class="ml-2 mt-2" v-if="sortBy === 'annualised-return'" >
            <span v-if="sortByDir === 'asc'">
              <i class="fa  fas fa-sort-up"></i>
            </span>
            <span v-if="sortByDir === 'desc'">
              <i class="fa  fas fa-sort-down"></i>
            </span>
          </span> 
        </th>
        <th style="cursor:pointer;max-width:100px;min-width:100px;white-space: normal; " @click="sort('realised-volatility')">
          <span class="mr-1">{{$t('tables.indices.columns.realisedVolatility')}}</span>
           <span class="ml-2 mt-2" v-if="sortBy === 'realised-volatility'" >
            <span v-if="sortByDir === 'asc'">
              <i class="fa  fas fa-sort-up"></i>
            </span>
            <span v-if="sortByDir === 'desc'">
              <i class="fa  fas fa-sort-down"></i>
            </span>
          </span> 
        </th>
        <th style="cursor:pointer;max-width:100px;min-width:100px;white-space: normal; " @click="sort('maxDrawdown')">
          <span class="mr-1">{{$t('tables.indices.columns.maxDrawdown')}}</span>
           <span class="ml-2 mt-2" v-if="sortBy === 'maxDrawdown'" >
            <span v-if="sortByDir === 'asc'">
              <i class="fa  fas fa-sort-up"></i>
            </span>
            <span v-if="sortByDir === 'desc'">
              <i class="fa  fas fa-sort-down"></i>
            </span>
          </span> 
        </th>
        <th style="cursor:pointer;max-width:100px;min-width:100px;white-space: normal; " @click="sort('sharpeRatio')">
          <span class="mr-1">{{$t('tables.indices.columns.sharpeRatio')}}</span>
           <span class="ml-2 mt-2" v-if="sortBy === 'sharpeRatio'" >
            <span v-if="sortByDir === 'asc'">
              <i class="fa  fas fa-sort-up"></i>
            </span>
            <span v-if="sortByDir === 'desc'">
              <i class="fa  fas fa-sort-down"></i>
            </span>
          </span> 
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in sortedItems" :key="item.id">
        <td style="min-width:200px; max-width:300px; overflow-wrap: break-word; word-break: break-all; white-space: pre-wrap;  ">
          <router-link
            :to="{ name: 'index-view', params: { id: item.id}}"
            style="display: block"
          >
              {{item.displayName}}
          </router-link>
        </td>
        <td>{{item.performance.ytdFormatted}}</td>
        <td>{{item.performance.mtdFormatted}}</td>
        <td>{{((item.statistic || {}).values || {}).annualisedReturnFormatted}}</td>
        <td>{{((item.statistic || {}).values || {}).realisedVolatilityFormatted}}</td>
        <td> 
          <span v-if="item.statistic">
            {{item.statistic.values.maxDrawdownFormatted}} <span  v-if="item.statistic.values.maxDrawdownFormatted">%</span>
          </span>
        </td>
        <td> 
          <span v-if="item.statistic">
            {{item.statistic.values.sharpeRatioFormatted}} <span  v-if="item.statistic.values.sharpeRatioFormatted"></span>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>